import { useEffect, useRef, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default function useUsersMap({ db }) {
  const usersDocsRef = useRef(collection(db, 'users'));
  const [usersMap, setUsersMap] = useState({});

  const initUsersMap = async () => {
    const r = {};
    const querySnapshot = await getDocs(query(usersDocsRef.current, where('role', '==', 'admin')));
    querySnapshot.forEach((query) => {
      const user = query.data();
      r[user.uid] = user;
    });
    setUsersMap(r);
  };

  useEffect(() => {
    initUsersMap();
  }, []);

  return { usersMap };
}
