import React from 'react';
import { Outlet } from 'react-router-dom';
import useRedirectOnUserCheck from './hooks/useRedirectOnUserCheck';

/**
 * @param {React.JSX.IntrinsicAttributes & import("react-router-dom").OutletProps} props
 */
export default function AuthChecking(props) {
  useRedirectOnUserCheck({ routeOnInvalid: '/login' });

  return <Outlet {...props} />;
}
