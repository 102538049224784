import postIcon from '../../../assets/images/sidebar/ic_post.svg';
import bigPictureLibraryIcon from '../../../assets/images/sidebar/ic_library.svg';
import wyskManagerIcon from '../../../assets/images/sidebar/ic_wysk_manager.svg';
import advertisementIcon from '../../../assets/images/sidebar/ic_ad.svg';
import reportIcon from '../../../assets/images/sidebar/ic_report.svg';

export const menuItems = [
  {
    route: '/posts',
    label: 'Posts',
    icon: postIcon,
  },
  {
    route: '/library',
    label: 'Big Picture',
    icon: bigPictureLibraryIcon,
  },
  {
    route: '/wysk',
    label: 'WYSK Manager',
    icon: wyskManagerIcon,
  },
  {
    route: '/advertisement',
    label: 'Advertisement',
    icon: advertisementIcon,
  },
  {
    route: '/reports',
    label: 'Reports',
    icon: reportIcon,
  },
];
