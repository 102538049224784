import React, { useContext } from 'react';
import styles from './index.module.css';
import { Dropdown, NavLink } from 'react-bootstrap';
import useAuth from '../../../contexts/firebase/useAuth';
import { UserContext } from '../../../contexts/firebase/contexts';
import _get from 'lodash/get';

export default function UserDropdown() {
  const user = useContext(UserContext);
  const { logout } = useAuth();

  return (
    <Dropdown>
      <Dropdown.Toggle as={NavLink} bsPrefix={styles.toggle}>
        {_get(user, 'loaded') && (
          <div className={styles.userImg} style={{ backgroundImage: `url(${_get(user, 'photoURL')})` }}>
            {!_get(user, 'photoURL') && _get(user, 'displayName[0]', '')}
          </div>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu bsPrefix={`dropdown-menu ${styles.menu}`}>
        <Dropdown.Item onClick={() => logout()}>Log out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
