import { useRef, useContext } from 'react';
import { FirebaseAuthContext } from './contexts';
import { GoogleAuthProvider, signOut, signInWithPopup } from 'firebase/auth';
import _isFunction from 'lodash/isFunction';

export default function useAuth() {
  const auth = useContext(FirebaseAuthContext);
  const providerRef = useRef(new GoogleAuthProvider());

  const logout = async ({ onSuccess, onError } = {}) => {
    try {
      if (!auth.currentUser) {
        _isFunction(onSuccess) && onSuccess();
        return;
      }
      await signOut(auth);
      _isFunction(onSuccess) && onSuccess();
    } catch (e) {
      console.error(`Failed to logout - ${e.code}. ${e.message}`, e, auth.currentUser);
      _isFunction(onError) && onError(e);
    }
  };

  const loginByModal = async ({ onSuccess, onError } = {}) => {
    try {
      if (auth.currentUser) {
        _isFunction(onSuccess) && onSuccess(auth.currentUser);
        return;
      }
      providerRef.current.addScope('https://www.googleapis.com/auth/userinfo.profile');
      providerRef.current.addScope('https://www.googleapis.com/auth/userinfo.email');
      await signInWithPopup(auth, providerRef.current);
      _isFunction(onSuccess) && onSuccess(auth.currentUser);
    } catch (e) {
      console.error(`Failed to login - ${e.code}. ${e.message}`, e);
      _isFunction(onError) && onError(e);
    }
  };

  return { loginByModal, logout };
}
