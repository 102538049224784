import React from 'react';
import { lazy, Suspense } from 'react';

const LazyAdvertisementDetailComponent = lazy(() => import(/* webpackChunkName: "aloglia-instant-search" */ './index'));

export default function LazyAdvertisementDetail(props) {
  return (
    <Suspense fallback={<div />}>
      <LazyAdvertisementDetailComponent {...props} />
    </Suspense>
  );
}
