import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import FirebaseContextProvider from './contexts/firebase/FirebaseContextProvider';
import AuthChecking from './AuthChecking';
import DefaultLayout from './components/layout/defaultlayout';
import LazyLogin from './pages/login/lazy';
import LazyPost from './pages/post/lazy';
import LazyPosts from './pages/posts/lazy';
import LazyPostCreate from './pages/postcreate/lazy';
import LazyPostEdit from './pages/postedit/lazy';
import LazyPostTypeSelector from './pages/posttypeselector/lazy';
import LazyBigPictureLibrary from './pages/bigpicture/lazy';
import LazyBigPictureLibraryManager from './pages/bigpicture/manager/lazy';
import LazyAdvertisement from './pages/advertisement/lazy';
import LazyAdvertisementDetail from './pages/advertisement/detail/lazy';
import LazyReports from './pages/reports/lazy';
import LazyWysk from './pages/wysk/lazy';
import LazyWyskManager from './pages/wysk/manager/lazy';

export default function App() {
  const queryClient = new QueryClient();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#08AE36',
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <FirebaseContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/login" index element={<LazyLogin />} />

              <Route element={<AuthChecking />}>
                <Route index path="*" element={<Navigate to="/posts" replace />} />
                <Route element={<DefaultLayout />}>
                  <Route path="/posts" element={<LazyPosts />} />
                  <Route path="/library" element={<LazyBigPictureLibrary />} />
                  <Route path="/advertisement" element={<LazyAdvertisement />} />
                  <Route path="/reports" element={<LazyReports />} />
                  <Route path="/wysk" element={<LazyWysk />} />
                </Route>
                <Route path="/:postTypePath/create" element={<LazyPostCreate />} />
                <Route path="/:postTypePath/edit/:postId" element={<LazyPostEdit />} />
                {/* Static News (WYSK) - Currently only supports post type ****************/}
                <Route path="/post/wysk/:originalPostId" element={<LazyPost />} />
                <Route path="/post/wysk/:originalPostId/create" element={<LazyPostCreate />} />
                {/* Edit original post */}
                <Route path="/post/wysk/:originalPostId/edit" element={<LazyPostEdit />} />
                {/* Edit post update */}
                <Route path="/post/wysk/:originalPostId/edit/:postUpdateId" element={<LazyPostEdit />} />
                {/* Static News (WYSK) ******************************************************/}
                <Route path="/postTypeSelector" element={<LazyPostTypeSelector />} />
                <Route path="/library/create" element={<LazyBigPictureLibraryManager />} />
                <Route path="/library/duplicate/:bigPictureId" element={<LazyBigPictureLibraryManager />} />
                <Route path="/library/edit/:bigPictureId" element={<LazyBigPictureLibraryManager />} />
                <Route path="/advertisement/create" element={<LazyAdvertisementDetail />} />
                <Route path="/advertisement/edit/:advertisementId" element={<LazyAdvertisementDetail />} />
                <Route path="/wysk/manager/:collectionType" element={<LazyWyskManager />} />
                <Route path="/wysk/manager/edit/:wyskId" element={<LazyWyskManager />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </FirebaseContextProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
}
