import { useContext, useEffect } from 'react';
import { UserContext } from '../contexts/firebase/contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../contexts/firebase/useAuth';

export default function useRedirectOnUserCheck(props = {}) {
  const { routeOnValid, routeOnInvalid } = props;
  const user = useContext(UserContext);
  const { logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !user.loaded) {
      return;
    }
    if (user.valid && routeOnValid) {
      location.pathname !== routeOnValid && navigate(routeOnValid);
    }
    if (!user.valid) {
      logout();
    }
    if (!user.valid && routeOnInvalid) {
      location.pathname !== routeOnInvalid && navigate(routeOnInvalid);
    }
  }, [user]);
}
