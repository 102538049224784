import { useRef, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import _get from 'lodash/get';

export default function useUserChecking(props) {
  const { db } = props;
  const initialized = useRef(false);
  const validEmails = useRef([]);

  const getAdminEmails = async () => {
    if (!db) {
      return;
    }
    const docRef = doc(db, 'roles', 'admin');
    const docSnapshot = await getDoc(docRef);
    const data = docSnapshot.exists() && docSnapshot.data();
    return _get(data, 'emails', []);
  };

  const isValidUser = async (user) => {
    if (!user || !user.email || !user.emailVerified) {
      return false;
    }
    if (user.email.match(/^[^@\s]+@wysk.ai[^@\s]*$/)) {
      return true;
    }
    const adminEmails = initialized.current ? validEmails.current : await getAdminEmails();
    return adminEmails.includes(user.email);
  };

  const init = async () => {
    if (initialized.current) {
      return;
    }
    validEmails.current = await getAdminEmails();
    initialized.current = true;
  };

  useEffect(() => {
    init();
  }, []);

  return { isValidUser };
}
