import React from 'react';
import { lazy, Suspense } from 'react';

const LazyModule = lazy(() => import(/* webpackChunkName: "advertisement" */ './index'));

export default function LazyAdvertisement(props) {
  return (
    <Suspense fallback={<div />}>
      <LazyModule {...props} />
    </Suspense>
  );
}
