import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.css';
import { menuItems } from './config';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import logo from '../../../assets/images/logo.svg';
import { CloseOutlined } from '@ant-design/icons';

/**
 * @param {object} props
 * @param {boolean} [props.isMenuToggle]
 * @param {Function} [props.setIsMenuToggle]
 * @param {string} [props.pageWrapId]
 * @param {string} [props.outerContainerId]
 * @returns {JSX.Element}
 */
function Sidebar({ isMenuToggle, setIsMenuToggle, ...props }) {
  const containerRef = useRef(null);
  const menuItemRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const numProps = Object.keys(props).length;

  const handleMenuItemClick = (i) => {
    setActiveIndex(i);
    if (setIsMenuToggle) setIsMenuToggle(false);
  };

  useEffect(() => {
    const activePath = menuItems.findIndex((menuItem) => menuItem.route === location.pathname);
    setActiveIndex(activePath >= 0 ? activePath : 0);
  }, [location.pathname]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.setProperty('--menuItemIndex', activeIndex);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (menuItemRef.current && containerRef.current) {
      const menuItemHeight = menuItemRef.current.offsetHeight;
      containerRef.current.style.setProperty('--menuItemHeight', `${menuItemHeight}px`);
    }
  }, [menuItemRef, containerRef]);

  const displaySideMenu = () => {
    return (
      <div className={clsx(styles.wrapper, { [styles.open]: isMenuToggle })}>
        {numProps > 0 && (
          <div className={styles.sideBarLogoImage}>
            <CloseOutlined style={{ fontSize: '24px' }} onClick={() => setIsMenuToggle(false)} />
            <img alt="Wysk.ai logo" src={logo} />
          </div>
        )}

        <div className={styles.container} ref={containerRef}>
          {menuItems.map((menuItem, i) => (
            <Link key={i} to={menuItem.route}>
              <div
                ref={i === activeIndex ? menuItemRef : null}
                className={clsx(styles.menuItem, { [styles.active]: activeIndex === i })}
                onClick={() => handleMenuItemClick(i)}
              >
                <div
                  style={{ backgroundImage: `url(${menuItem.icon})` }}
                  className={clsx(styles.menuItemIcon, { [styles.active]: activeIndex === i })}
                ></div>
                <div className={clsx(styles.menuItemLabel, { [styles.active]: activeIndex === i })}>
                  {menuItem.label}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  };

  return displaySideMenu();
}

export default Sidebar;
