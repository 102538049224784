import React, { useEffect, useReducer, useRef, useState } from 'react';
import {
  FirebaseAuthContext,
  FirebaseStorageContext,
  FirestoreContext,
  UserContext,
  UsersMapContext,
} from './contexts';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import useUserChecking from './useUserChecking';
import useUpdateDatabaseUser from './useUpdateDatabaseUser';
import useUsersMap from './useUsersMap';
import _isFunction from 'lodash/isFunction';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  ...(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID && {
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  }),
};

const initialState = { loaded: false, valid: false };
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        ...state,
        ...action.payload,
        loaded: true,
        valid: action.payload != null,
      };
    default:
      return state;
  }
};

export default function FirebaseContextProvider({ children }) {
  const firebaseAppRef = useRef(initializeApp(firebaseConfig));
  const firestoreRef = useRef(getFirestore(firebaseAppRef.current));
  const firebaseStorageRef = useRef(getStorage(firebaseAppRef.current));
  const firebaseAuthRef = useRef(getAuth(firebaseAppRef.current));

  const [user, dispatch] = useReducer(reducer, initialState);
  const [initialized, setInitialized] = useState(false);

  const { isValidUser } = useUserChecking({ db: firestoreRef.current });
  const { addOrUpdateUser } = useUpdateDatabaseUser({ db: firestoreRef.current });
  const { usersMap } = useUsersMap({ db: firestoreRef.current });

  const handleAuthStateChanged = async (user) => {
    if (user) {
      const isValid = await isValidUser(user);
      if (isValid) {
        dispatch({ type: 'UPDATE_USER', payload: user });
      } else {
        dispatch({ type: 'UPDATE_USER', payload: null });
      }
    } else {
      dispatch({ type: 'UPDATE_USER', payload: null });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuthRef.current, handleAuthStateChanged);
    setInitialized(true);

    return () => {
      _isFunction(unsubscribe) && unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user.loaded && user.valid) {
      addOrUpdateUser(user);
    }
  }, [user]);

  if (!initialized) {
    return null;
  }

  return (
    <FirestoreContext.Provider value={firestoreRef.current}>
      <FirebaseStorageContext.Provider value={firebaseStorageRef.current}>
        <FirebaseAuthContext.Provider value={firebaseAuthRef.current}>
          <UserContext.Provider value={user}>
            <UsersMapContext.Provider value={usersMap}>{children}</UsersMapContext.Provider>
          </UserContext.Provider>
        </FirebaseAuthContext.Provider>
      </FirebaseStorageContext.Provider>
    </FirestoreContext.Provider>
  );
}
