import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import DefaultNavbar from './DefaultNavbar';
import styles from './index.module.css';
import Sidebar from '../sidebar';
import { useMediaQuery } from 'react-responsive';

/**
 * Only enable the burger menu for mobile and tablets
 * All other screen sizes will show the sidebar on the right
 * @returns {JSX.Element}
 */
export default function DefaultLayout() {
  const [isMenuToggle, setIsMenuToggle] = useState(false);
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 768px)',
  });

  return (
    <div id="App">
      <DefaultNavbar
        isMobileDevice={isMobileDevice}
        isMenuToggle={isMenuToggle}
        setIsMenuToggle={(e) => setIsMenuToggle(e)}
      />
      <div className={styles.container}>
        {isMobileDevice ? (
          <Sidebar
            pageWrapId={'page-wrap'}
            outerContainerId={'App'}
            isMenuToggle={isMenuToggle}
            setIsMenuToggle={(e) => setIsMenuToggle(e)}
          />
        ) : (
          <Sidebar />
        )}
        <div id="page-wrap" className={styles.outlet}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
