import { useRef } from 'react';
import { collection, doc, setDoc } from 'firebase/firestore';

export default function useUpdateDatabaseUser({ db }) {
  const usersRef = useRef(collection(db, 'users'));

  const _setUser = async (user) => {
    const docRef = doc(usersRef.current, user.uid);
    await setDoc(docRef, user);
  };

  const addOrUpdateUser = async (user) => {
    try {
      if (!user) {
        console.error('Failed to add / update user - unauthorized');
        return;
      }
      const { uid, displayName, email, photoURL, phoneNumber, providerData, metadata } = user;
      await _setUser({ role: 'admin', uid, displayName, email, photoURL, phoneNumber, providerData, ...metadata });
      return user;
    } catch (e) {
      console.error(`Failed to update user - ${e.code}`, e, user);
    }
  };

  return { addOrUpdateUser };
}
