import React, { lazy, Suspense } from 'react';

const LazyModule = lazy(() => import(/* webpackChunkName: "post-type-selector" */ './index'));

export default function LazyNewPost(props) {
  return (
    <Suspense fallback={<div />}>
      <LazyModule {...props} />
    </Suspense>
  );
}
