import React from 'react';
import styles from './DefaultNavbar.module.css';
import logo from '../../../assets/images/logo.svg';
import { MenuOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import UserDropdown from '../userdropdown';

/**
 * @param {object} props
 * @param {boolean} props.isMobileDevice
 * @param {boolean} props.isMenuToggle
 * @param {Function} props.setIsMenuToggle
 * @returns {JSX.Element}
 */
export default function DefaultNavbar({ isMobileDevice, isMenuToggle, setIsMenuToggle }) {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        {!isMobileDevice ? (
          <Link to="/">
            <img alt="Wysk.ai logo" src={logo} />
          </Link>
        ) : (
          <MenuOutlined style={{ fontSize: '24px', color: '#000' }} onClick={() => setIsMenuToggle(!isMenuToggle)} />
        )}
      </div>
      <div className={styles.rightContainer}>
        <UserDropdown />
      </div>
    </div>
  );
}
